import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import title_image from '../images/titles/blog.jpg'


const pageTitle = "Blog";
const pageSlug = "blog";


class BusinessConsulting extends React.Component{
    render(){
      return(
        <Layout>

<section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">Are you looking for some expert advice or practical information that can solve some of your concerns about business and technology?</h6>
        </div>
</section>

              
<section id="p_technology_services" className="section static-page page-content">
                  <div className="container">
                      <div className="columns">
                          <div className="column is-12 content-area">

                              <p>If you have any questions that are related to business and technology, our blogs can help point you in the right direction. Whether you’re interested in articles, news, or resource-related information we would be glad to help in any way we can.
                              </p>
                              <p>Our dedicated team is continually updating our list of expert opinions to give you a greater insight into how business is constantly evolving.</p>
                              <p>Sharing knowledge and information is important, we regularly discuss our personal experience, expertise, and thoughts on how clients can work together.</p>
                              <p><a href="">Discover More</a></p>
                              <span className="break"></span>
                              <span className="break"></span>
                              <p>Depending on your needs, our experts continuously share and create knowledge of business, technology, industry, digital, and culture topics. Our topics span from simple how-tos to best practices, we are sure you will find information that you will be able to use.</p>
                              <span className="break"></span>
                              <span className="break"></span>

                              
                              <section id="_insights-blog" className="section insights-blog blog-list blue">
        <div className="container">
          <div className="title-area">
            <h1 className="title">Our latest blogs</h1>
          </div>
          <div className="columns is-multiline">
            <div className="single column is-4">
                <Link to="/blog/choosing-the-right-business-process-management-solution/" className="inner">
                  <p className="category"><span className="line"></span>BUSINESS</p>
                  <h2 className="title">Choosing the right Business Process Management Solution (BPMS)</h2>
              </Link>
            </div>

            <div className="single column is-4">
                <Link to="/blog/improving-customer-experience-with-an-advisory-board/" className="inner">
                  <p className="category"><span className="line"></span>TECHNOLOGY</p>
                  <h2 className="title">Improving Customer Experience with an Advisory Board</h2>
                </Link>
            </div>

            <div className="single column is-4">
                <Link to="/blog/wasteful-processes-slow-down-software-delivery-efficiency-and-effectiveness/" className="inner">
                  <p className="category"><span className="line"></span>TECHNOLOGY</p>
                  <h2 className="title">Wasteful Processes Slow down Software Delivery Efficiency and Effectiveness</h2>
                </Link>
            </div>

          </div>
        </div>
      </section>
   



                              <span className="break"></span>
                              <span className="break"></span>
                              <span className="break"></span>
                              <hr/>
                              <h3>Discover more, keep reading!</h3>
                              <p>We help our clients improve and optimize their processes, increase their efficiency, improve their technology, and strive to help them compete in an ever-changing digital world. <a href="">Discover more about how we work!</a></p>


                              
                           
 
                  
                </div>
            </div>
        </div>
      </section>



</Layout>
    )
  }
}

export default BusinessConsulting
